@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

html,
body,
#root{
    height: 100%;
}

body,
input,
button{
    font-family: 400 14px Roboto, sans-serif;
}