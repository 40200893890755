.veiculo-container .strong-group {
  display: flex;
}

.veiculo-container .strong-group p {
  margin-left: 10px;
  margin-right: 10px;
}

.veiculo-container table .cabecalho {
  font-size: 20px;
}

.veiculo-container table th {
  padding-left: 10px;
}

.veiculo-container table td {
  text-align: center;
}
