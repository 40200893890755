@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
* {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
}

html,
body,
#root{
    height: 100%;
}

body,
input,
button{
    font-family: 400 14px Roboto, sans-serif;
}
.estacionamento-container form {
    margin: 20px 20px;
}

.estacionamento-container .button-create {
    margin-right: 40px;
}

.estacionamento-container .buttons-container {
    margin-top: 10px
}

.estacionamento-container form legend {
    margin: 20px 0;
}

.estacionamento-container form .map {
    height: 150px;
    margin: 0 100px;
}

.estacionamento-list-default-list-container {
    margin: 20px 20px;
}



.rede-estacionamento-container form {
    margin: 20px 20px;
}

.rede-estacionamento-container .button-create {
    margin-right: 40px;
}

.rede-estacionamento-container .buttons-container {
    margin-top: 10px
}

.rede-estacionamento-list-default-list-container {
    margin: 20px 20px;
}
  



.veiculo-container .strong-group {
  display: flex;
}

.veiculo-container .strong-group p {
  margin-left: 10px;
  margin-right: 10px;
}

.veiculo-container table .cabecalho {
  font-size: 20px;
}

.veiculo-container table th {
  padding-left: 10px;
}

.veiculo-container table td {
  text-align: center;
}

